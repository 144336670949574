<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(ELEMENT_NAME + '.search.error.fetching_data')}}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
        <element-info-card 
          :element-data="elementData" 
        />
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-button 
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
        {{$t('actions.back')}}
        </b-button>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BButton, BAlert, BLink,
} from 'bootstrap-vue'
import ElementStoreModule from '@/views/freezer/pending/storeModule'
import ElementInfoCard from '@/views/freezer/pending/view/InfoCard.vue'
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    // Local Components
    ElementInfoCard,
  },
  data() {
    return {
      elementData: {},
      ELEMENT_APP_STORE_MODULE_NAME: '',
      ELEMENT_NAME: 'freezer',
    }
  },
  mounted() {
    this.ELEMENT_APP_STORE_MODULE_NAME = 'app-freezer-pend-v-' + this.ELEMENT_NAME
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    this.fetchElement()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    router() {
      return router
    },
    fetchElement() {
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id })
      .then(response => { 
        this.elementData = response.data 
      })
      .catch(error => {
        if (error.response.status === 404) {
          console.info('404', this.elementData)
          this.elementData = undefined
        }
      })
    },
  }
}
</script>

<style>

</style>
