<template>
  <b-card>
    <b-card-body>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="12"
          xl="12"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <!-- Media -->
            <b-media>
              <b-col
                cols="12"
                md="4"
                class="text-center mb-1"
              >
                <b-img
                  :src="avatar"
                  text=""
                  variant="light-success"
                  fluid
                  class="border"
                />
              </b-col>
            </b-media>
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h2 class="mb-1 text-primary text-uppercase">
                  <b>Nevera</b>
                </h2>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="12"
        >
          <h3 class="mb-1 text-primary text-uppercase">
            <b>{{ $t('freezer.section.detination_info') }}</b>
          </h3>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th>
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t('patient.object.documentType') }}</span>
              </th>
              <td>
                {{ getTypeValue(elementData.case.patient, 'document_type') }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t('patient.object.identity') }}</span>
              </th>
              <td>
                {{ elementData.case.patient.identity }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t('patient.object.birthdate') }}</span>
              </th>
              <td>
                {{ getDate(elementData.case.patient, 'birthdate') }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t('patient.object.age') }}</span>
              </th>
              <td>
                {{ age }}
              </td>
            </tr>

          </table>
          <br><br>
          <h3 class="mb-1 text-primary text-uppercase">
            <b>{{ $t('contact.section.contact_data') }}</b>
          </h3>
          <b-row>
            <b-table
              striped
              hover
              :items="elementData.case.patient.contacts"
              :fields="contact_data_fields"
            />
          </b-row>
          <br><br>

          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="12"
            >
              <h3 class="mb-1 text-primary text-uppercase">
                <b>{{ $t('freezer.section.delivery_info') }}</b>
              </h3>
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th>
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('freezer.object.address') }}</span>
                  </th>
                  <td>
                    {{ elementData.address }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('freezer.object.department') }}</span>
                  </th>
                  <td>
                    {{ getTypeValue(elementData, 'address_department') }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('freezer.object.city') }}</span>
                  </th>
                  <td>
                    {{ getTypeValue(elementData, 'address_city') }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('freezer.object.town') }}</span>
                  </th>
                  <td>
                    {{ getTypeValue(elementData, 'address_town') }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('freezer.object.population_zone') }}</span>
                  </th>
                  <td>
                    {{ getTypeValue(elementData, 'address_population_zone') }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('freezer.object.observations') }}</span>
                  </th>
                  <td>
                    {{ elementData.observations }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
      <br><br>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BTable, BMedia, BImg,
} from 'bootstrap-vue'
import moment from 'moment'

import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody, BTable, BMedia, BImg,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatar: null,
      elementName: 'freezer',
      contact_data_fields: [
        {
          key: 'relationship.value',
          label: 'parentesco',
        },
        {
          key: 'type.value',
          label: 'Tipo',
        },
        {
          key: 'data',
          label: 'Información',
        },
        {
          key: 'department.value',
          label: 'Departamento',
        },
        {
          key: 'city.value',
          label: 'Ciudad',
        },
      ],
    }
  },
  computed: {
    patient_name: {
      get() {
        return `${this.elementData.first_name} ${this.elementData.second_name} ${this.elementData.first_surname} ${this.elementData.second_surname}`
      },
    },
    birthdate: {
      get() {
        return moment(this.elementData.birthdate, 'DD/MM/YYYY').format('DD/MM/YYYY')
      },
    },
    age: {
      get() {
        if (this.elementData.case.patient.birthdate) {
          let date = this.elementData.case.patient.birthdate
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return moment().diff(date, 'years')
        }
        return null
      },
    },
  },
  setup() {
    return {
      avatarText,
      getTypeValue(element, type) {
        return element[type] ? element[type].value : ''
      },
      getDate(element, type) {
        return element[type] ? moment(element[type], 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      },
      resolveElementStatusVariant(status) {
        if (status === 0) return 'warning'
        return 'success'
      },
      resolveElementStatusMessage(status) {
        if (status === 0) return this.$t(`${this.elementName}.status.inactive`)
        return this.$t(`${this.elementName}.status.active`)
      },
    }
  },
}
</script>

<style>

</style>
